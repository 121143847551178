import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PrinterScan.css';
import { FaEdit, FaTrash, FaAndroid } from 'react-icons/fa'; // Importer l'icône Android

const store_id = window.env.REACT_APP_STORE_ID;
const base_url = window.env.REACT_APP_BASE_URL;

const fetchPrinters = async (setPrinters) => {
    try {
        const response = await axios.get(`${base_url}printers/?store_id=${store_id}`);
        setPrinters(response.data);
    } catch (err) {
        console.error('Échec de la récupération des imprimantes');
    }
};

const PrinterScan = () => {
    const [loading, setLoading] = useState(false);
    const [printers, setPrinters] = useState([]);
    const [error, setError] = useState(null);
    const [newPrinter, setNewPrinter] = useState({ name: '', ip: '', port: '', activated: false });
    const [editingPrinter, setEditingPrinter] = useState(null);

    useEffect(() => {
        fetchPrinters(setPrinters);
    }, []);

    const handleAddPrinter = async () => {
        setError(null);

        if (!newPrinter.ip || !newPrinter.port) {
            setError('Adresse IP et Port sont requis.');
            setTimeout(() => setError(''), 1000);
            return;
        }

        try {
            const response = await axios.post(`${base_url}printers/`, { ...newPrinter, store_id });
            setPrinters([...printers, response.data]);
            setNewPrinter({ name: '', ip: '', port: '' });
        } catch (err) {
            setError("Échec de l'ajout de l'imprimante");
            setTimeout(() => setError(''), 1000);
        }
    };

    const activate = async (printer) => {
        setError(null);

        try {
            await axios.put(`${base_url}printers/`, {
                id: printer.id,
                store_id: store_id,
                name: printer.name,
                ip: printer.ip,
                port: printer.port,
                activated: !printer.activated
            });
            setPrinters((prev) =>
                prev.map((prntr) =>
                    prntr.id === printer.id
                        ? {
                              ...prntr,
                              store_id: store_id,
                              name: printer.name,
                              ip: printer.ip,
                              port: printer.port,
                              activated: !printer.activated
                          }
                        : {
                              ...prntr,
                              store_id: store_id,
                              name: prntr.name,
                              ip: prntr.ip,
                              port: prntr.port,
                              activated: false
                          }
                )
            );
        } catch (err) {
            setError("Échec de la mise à jour de l'imprimante");
            setTimeout(() => setError(''), 1000);
        }
    };

    const handleEditSave = async () => {
        setError(null);

        if (!editingPrinter.ip || !editingPrinter.port) {
            setError('Adresse IP et Port sont requis.');
            setTimeout(() => setError(''), 1000);
            return;
        }

        try {
            await axios.put(`${base_url}printers/`, editingPrinter);
            setPrinters(printers.map(p => p.id === editingPrinter.id ? editingPrinter : p));
            setEditingPrinter(null);
        } catch (err) {
            setError("Échec de la mise à jour de l'imprimante");
            setTimeout(() => setError(''), 1000);
        }
    };

    const handleEditClick = (printer) => {
        setEditingPrinter(printer);
    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${base_url}printers/`, { data: { id } });
            setPrinters(printers.filter(printer => printer.id !== id));
        } catch (err) {
            console.error("Échec de la suppression de l'imprimante");
        }
    };

    const printTest = (printer) => {
        if (!printer.ip || !printer.port) {
            alert('L\'adresse IP et le port de l\'imprimante sont requis.');
            return;
        }
    
        const dataToSend = {
            ip: printer.ip,
            port: printer.port,
            text: 'Ceci est un test d\'impression'
        };
    
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(dataToSend));
            console.log('Message envoyé:', dataToSend);
        } else {
            alert('Erreur : Interface WebView non disponible.');
            console.error('ReactNativeWebView n\'est pas disponible.');
        }
    };
    
    return (
        <div className="orders-container">
            {/* Lien avec logo APK au-dessus du tableau */}
            <div className="apk-button-container">
                <a href="https://drive.google.com/file/d/1hYtvNr-69PcwlB2_xhbfc1jkm58Gnk7d/" target="_blank" className="apk-button">
                    <FaAndroid /> Télécharger APK
                </a>
            </div>

            <div className="add-menu">
                <input
                    type="text"
                    placeholder="Nom de l'imprimante"
                    value={newPrinter.name}
                    onChange={(e) => setNewPrinter({ ...newPrinter, name: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Adresse IP"
                    value={newPrinter.ip}
                    onChange={(e) => setNewPrinter({ ...newPrinter, ip: e.target.value })}
                    required
                />
                <input
                    type="text"
                    placeholder="Port"
                    value={newPrinter.port}
                    onChange={(e) => setNewPrinter({ ...newPrinter, port: e.target.value })}
                    required
                />
                <button onClick={handleAddPrinter} disabled={loading}>
                    Ajouter
                </button>
            </div>

            {error && <p className="errorResult">Erreur : {error}</p>}
            <table className="printer-table">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Adresse IP</th>
                        <th>Port</th>
                        <th>Actif</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {printers.length > 0 ? (
                        printers.map((printer) => (
                            <tr key={printer.id}>
                                <td>
                                    {editingPrinter && editingPrinter.id === printer.id ? (
                                        <input
                                            type="text"
                                            value={editingPrinter.name}
                                            onChange={(e) => setEditingPrinter({ ...editingPrinter, name: e.target.value })}
                                        />
                                    ) : (
                                        printer.name
                                    )}
                                </td>
                                <td>
                                    {editingPrinter && editingPrinter.id === printer.id ? (
                                        <input
                                            type="text"
                                            value={editingPrinter.ip}
                                            onChange={(e) => setEditingPrinter({ ...editingPrinter, ip: e.target.value })}
                                        />
                                    ) : (
                                        printer.ip
                                    )}
                                </td>
                                <td>
                                    {editingPrinter && editingPrinter.id === printer.id ? (
                                        <input
                                            type="text"
                                            value={editingPrinter.port}
                                            onChange={(e) => setEditingPrinter({ ...editingPrinter, port: e.target.value })}
                                        />
                                    ) : (
                                        printer.port
                                    )}
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked={printer.activated} onChange={() => activate(printer)} />
                                        <span className="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    {editingPrinter && editingPrinter.id === printer.id ? (
                                        <div className="constraint-form-container">
                                            <button className="save-button" onClick={handleEditSave}>
                                                Ajouter
                                            </button>
                                            <button className="save-button" onClick={() => setEditingPrinter(null)}>
                                                Annuler
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <FaEdit
                                                className="fa-edit"
                                                onClick={() => handleEditClick(printer)}
                                            />
                                            <FaTrash
                                                className="fa-trash"
                                                onClick={() => handleDeleteClick(printer.id)}
                                            />
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="no-devices">Aucune imprimante trouvée</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Bouton "Tester" en bas à droite */}
            <div className="test-button-container">
                <button className="test-button" onClick={() => printTest(printers[0])}>
                    {loading ? 'Tester...' : 'Tester'}
                </button>
            </div>
        </div>
    );
};

export default PrinterScan;
